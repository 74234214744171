.iconStyle {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 2em;
	height: 2em;
	
}

.textcontainer {
	padding: 15px;
}

.tourencard {
	position: relative;
    border-style: none;
    border-radius:5px;
    height:100%;
	font-weight: 100;
	background-color: #023047;
	-webkit-box-shadow: 10px 10px 32px -14px rgba(0, 0, 0, 0.42);
	-moz-box-shadow: 10px 10px 32px -14px rgba(0, 0, 0, 0.42);
	box-shadow: 10px 10px 32px -14px rgba(0, 0, 0, 0.42);
}

.tourencarddetails {
	font-weight: 100;
}
.tourencardheading {
    font-weight: 400;
    font-size: 1.2em;
}

.tourencardoverlay {
	background-color:  rgb(27, 55, 105, 0.9);
	display: none;
}

.tourencard:hover {
	animation-name: increaseCardSize;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.1s;
	width: 105%;
	left: -8px;
	border-radius:5px;
	background-color: #03202e;
	cursor: pointer;
}

.tourencardfontoverlay {
	overflow: hidden;
	bottom: 0px;
}

.tourencard:hover .tourencardfontoverlay {
	animation-name: slideUpCardDetails;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;

	font-size: 1.1em;
}

.tourencard:hover .tourencarddetails {
	display: block;
}

@keyframes increaseCardSize {
	0% {
		width: 100%;
		left: 0px;
	}

	100% {
		width: 105%;
		left: -8px;
	}
}

@keyframes slideUpCardDetails {
	0% {
		font-size: 1em;
	}

	25% {
		font-size: 0.9em;
	}

	100% {
		font-size: 1.1em;
	}
}

.section-all .section-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	background: rgba(0, 0, 0, .1);
}

.filter-icon {
	-webkit-box-shadow: 10px 10px 32px -14px rgba(0, 0, 0, 0.42);
	-moz-box-shadow: 10px 10px 32px -14px rgba(0, 0, 0, 0.42);
	box-shadow: 10px 10px 32px -14px rgba(0, 0, 0, 0.42);
}

.filter-icon:hover {
	cursor: pointer;
}

.filter-icon-inactive {
	color: lightgray;
	text-decoration: line-through;
}

input[type=radio] {
	visibility: hidden;
}
