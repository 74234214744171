/* Slider wrapper*/

* {
  font-family: 'Roboto', sans-serif;
}

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Slider */
.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sliderContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  z-index: 1000;

}

.sliderPagination {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  text-align: center;
  z-index: 1000;
}

.label {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 5px;
  border: solid 2px rgb(255, 255, 255);
  cursor: pointer;
}



/* Slider Inner Slide Effect */
.h2 {
  position: relative;
  color: white;
  font-weight: 900;
  
  font-size: 50px;
  font-weight: 500;
  line-height: 1.2;
  opacity: 1;
  margin-top: 0;
  letter-spacing: 2px;

}

.h4 {
  position: relative;
  font-size: 22px;
  color: rgba(224, 224, 224, 0.8);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
  opacity: 1;


}


.arrow {
  font-size: 50px;
}

.arrow:hover {
  transform: scale(1.2);
  transform: translate(0, -20);
  cursor: pointer;
}


.sliderContentText {
  width:80%;
  /*
  margin:25px;
  padding: 50px;
  background-color:rgba(15, 19, 1, 0.473);
  border-style:solid;
  border-width:0px;
  border-color: rgba(165, 161, 184, 0.432);
  border-radius: 5px;
  -webkit-box-shadow: 10px 10px 49px 31px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 49px 31px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 49px 31px rgba(0,0,0,0.75);
  */
}


/* Responsive */
@media only screen and (max-width: 768px) {
  .sliderContentText {
    text-align: center;
  }


  .arrow {
    height: 20px;
    margin-top: 30px;
    font-size: 30px;
  }

  .h2 {
    font-size: 20px;
  }

  .h4 {
    font-size: 16px;
  }

  .sliderContent {
    padding: 0 2%
  }

  .navigation {
    padding: 0 2%;
  }

  .navigationLeft {
    display: none;
  }




}