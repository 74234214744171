.courseContainer {
    -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 10px 10px 32px 0px rgba(0, 0, 0, 0.24);
    box-shadow: 10px 10px 32px 0px rgba(0, 0, 0, 0.24);
    text-align: left;
    margin-bottom: 50px;
    background-color: white;
    

}

.courseText {
    color: gray;
}

.courseText ul {
    list-style-type: none;
}

.courseText li{
    font-weight: 600;
}

.courseDetail {
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 20px;
}