.main{
    color: white;
    padding-top:20px;
    text-align:center;
    height:100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.text{
    padding-left:10%;
    padding-right:10%;
    text-align: center;
}
.stores{
    margin-right:15px;
    margin-top:15px;
}

