.background{
    color: white;
    text-align: center;
    width: 80%;
    margin: auto;
    padding-bottom:50px;
}

.guidegrid{
    margin-top:40px;
}



  
 
  

  
    
  
