/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/roboto-v30-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/roboto-v30-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-v30-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-v30-latin-100.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-v30-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-v30-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}


* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


html,
body,
.container {
  margin: 0;
  height: 100vh;
  color: white;
}

p {
  font-size: 0.8em;
  font-weight: 100;
}


a {
  text-decoration: none;
  color: white;
}

body {
  font-family: 'Roboto', sans-serif;
}

header h1 {
  color: white;
}

.header {
  background-color: #00334C;
  position: fixed;
  z-index: 1000;
  width: 100%;
  text-align: left;
  top: 0;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  font-size: 2em;
}


/* heading*/
@media(min-width:700px) {
  h1 {
    font-size: 4.5em;
    font-weight: 900;
  }

  p {
    font-size: 1em;
  }

  .heading h1 {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .heading h2 {
    font-weight: 200;
    font-size: 50px;
  }
}

/* NAVBAR mobile*/



#opener:hover {
  cursor: pointer;
}

#opener:active~.navbar-collapse {
  display: block;
}

#opener:hover .navbar-collapse {
  display: block;

}

.navbar {
  letter-spacing: 2px;
  background-color: #00334C;
  width: 100%;
  position: fixed;
  height: 55px;
  text-align: right;
}

.navbar-collapse {
  display: none;
}


.navbar .menu-icon {
  display: block;
  background-image: url('./menu-icon.png');
  background-repeat: no-repeat;
  margin-left: 15px;
  margin-top: 17px;
  height: 40px;
  padding-right: 30px;
}

.navbar .menu-icon h3 {
  padding-top: 5px;
  font-weight: 200;
}

.navbar a {
  text-decoration: none;
  color: white;
}


.navbar ul {
  list-style-type: none;
  padding-top:40px;
}

.navbar li {
  height: 20px;
}

.navbar li:hover{
  color:gray;
}

/* side menu*/

.open-drawer {
  top: 0;
  bottom: 0;
  left: 0;
  margin: 0;

  background-color: rgba(27, 55, 105);
  padding: 15px;
  position: fixed;
  z-index: 2000;
  width: 250px;
  display: block;

  transform: translate3d(-100%, 0, 0);
  -webkit-transition: transform .3s ease-in-out;
  -moz-transition: transform .3s ease-in-out;
  -o-transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out;

}

.open-drawer-active {
  transform: translate3d(0, 0, 0);

}

.open-drawer li {
  text-align: left;
  margin-bottom: 20px;
}



/*Desktop*/
@media(min-width:700px) {
  .navbar {
    letter-spacing: 2px;
    width: 100%;
  }

  .navbar-collapse {
    display: block;
  }

  .navbar .menu-icon {
    display: none;
  }
  
.navbar ul {
  list-style-type: none;
  padding-top:0px;
}

  .open-drawer {
    display: none;
  }

  .navbar-color {
    background-color: #00334C;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
  }

  @keyframes fadeInOpacity {
    0% {
      background-color: rgba(28, 32, 32, 0);
    }

    100% {
      background-color: rgb(27, 55, 105, 0.9);
    }
  }

  .navbar-transparent {
    background-color: transparent;

  }

  .navbar ul {
    align-content: right;
    margin-right: 80px;
  }

  .navbar li {
    display: inline;
    margin-right: 20px;

  }


  .navbar a:hover {
    color: gray;
  }

  navbar-header {
    padding-top: 7px;
  }
}

/* 
Cards

/* touren card */


.footer {
  display: flex;
  justify-content: space-between;
  min-height: 20px;
  background-color: #00334C;
  padding: 10px;
  color: white;
}