.guideCardContainer {
    -webkit-box-shadow: 10px 10px 32px 0px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 10px 10px 32px 0px rgba(0, 0, 0, 0.24);
    box-shadow: 10px 10px 32px 0px rgba(0, 0, 0, 0.24);
    text-align: left;
    margin-bottom: 50px;
    background-color: white;
}

.guide-card-container .guide-card-text {
    margin: 20px;
}

.imageBackground {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
}

.textdiv {
    padding: 40px;
    color: gray;
}

.list {
    display: flex;
    flex-direction: column;
    border-top-style: solid;
    border-width: 1px;
    padding-left: 0px;
    padding-top: 25px;
    justify-content: flex-start;
    list-style-type: none;
}

.list li{
    margin-top:10px;
}

.listheading {
    font-size: 1em;
    font-weight: 600;
}

.listtext {
    font-size: 1em;
    font-weight: 200;
}

.contactButton {
    margin-top: 25px;
    margin-bottom: 15px;
    width: 100%;
}

.guide-card-header {
    height: 100%;
    width: 100%;
}