.sectionSettings{
    color: white;
    text-align:center;
    width: 80%;
    margin: auto;
    padding-bottom: 50px;
    padding-top:100px;
}

.sectionHeading{
    margin-top: 0px;
}